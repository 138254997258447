<template>
  <div id="ESG">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg_esg04">
          <h3 class="title title_esg">인권경영</h3>
          <p class="txt txt_esg">
            SK텔링크는 임직원을 비롯한 <br />
            모든 사람들의 자유와 인권을 존중합니다.
          </p>
          <img
            src="/content/images/sub_header_bg_esg04_mo.jpg"
            alt="비쥬얼 이미지"
          />
        </div>

        <div class="company esg">
          <div class="esg_box">
            <div class="esg_content">
              <h5 class="esg_content_title">인권선언</h5>
              <p class="esg_content_sub">
                SK텔링크는 '기업의 영구 존속, 발전을 통해 고객·구성원·주주에
                대한 가치를 창출하여 사회·경제발전에 핵심적인 역할을 수행하고<br
                  class="pc-only"
                />
                나아가 인류의 행복에 공헌한다'는 SKMS 규범을 근간으로 삼아,
                사회적 책임과 규범을 준수하며 비즈니스 목표를 달성하는 것을
                지향합니다.<br class="pc-only" />
                당사의 사회적 책임은 고객, 구성원, 비즈니스 파트너, 지역사회에
                높은 수준의 인권 존중에 관한 책무를 정의하고 있습니다.
              </p>
              <p class="esg_content_sub">
                SK텔링크는 높은 수준의 글로벌 시민의식을 실천하는 기업으로서<br
                  class="pc-only"
                />
                일상에서 상품과 서비스, 계약, 구매, 기타 비즈니스 활동을 통해
                접하는 모든 이해관계자들의 근본적 권리를 존중합니다.
              </p>
              <p class="esg_content_sub">
                SK텔링크는 ‘세계인권선언(Universal Declaration of Human
                Rights)’과 ‘유엔 기업과 인권 이행 원칙(UN Guiding Principles on
                Business and Human Rights; Ruggie<br class="pc-only" />
                Framework)’이 제시하고 있는 인권 원칙에 대하여 공개적 지지를
                선언하고, 인권 존중과 관련한 책임을 다할 것을 천명하는
                바입니다.<br class="pc-only" />
                SK텔링크는 사업 과정에서 일어날 수 있는 인권 침해를 방지하기
                위해 노력할 뿐만 아니라 침해의 방조 또는 공모 행위를 피하기 위한
                최선의 노력을 기울일 것입니다.
              </p>
            </div>
            <!-- 2025/01/03 히든 처리 -->
            <!-- <ul class="esg_pdf_list">
              <li>
                <span class="pdf_title">CEO 인권정책 선언문</span>
                <span class="pdf_txt"></span>
                <a
                  class="pdf_btn"
                  href="/content/download/CEO_인권정책_선언문(최종).pdf"
                  target="_blank"
                  >전문 보기</a
                >
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "EsgHumanRight",
  metaInfo: {
    title: "인권경영 | 사회 | ESG",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tab_type01 {
  border-top: 1px solid #dadada;
}
.esg {
  margin: 87px 0 100px;

  .esg_box {
    padding: 0 0 20px;
    text-align: center;

    .table_type04 {
      margin-top: 20px;
      th,
      td {
        vertical-align: middle;
      }
    }
    sub {
      font-size: 0.5em;
      vertical-align: sub;
    }

    .inner {
      width: 100%;

      img {
        max-width: 785px;
      }
    }
  }

  .esg_pdf_list {
    margin-top: 50px;
  }

  .esg_content {
    margin-top: 0 !important;
  }

  .cp_area.pb0 {
    padding-bottom: 0;
  }
  .ui-btn-wrap {
    margin-top: 80px;
    .ui-btn {
      display: inline-block;
      padding: 0 44px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }

  .esg_report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 117px;
    padding: 90px 20px;
    text-align: center;
    background-color: #f9f9f9;

    * {
      letter-spacing: -0.03em;
    }

    .report-title-wrap {
      text-align: center;

      img {
        width: 74px;
        height: 74px;
      }

      .report-title {
        margin-top: 30px;
        font-size: 44px;
        line-height: 44px;
        color: #444444;
      }
    }

    .report-txt {
      margin-top: 25px;
      font-size: 15px;
      line-height: 26px;

      > p {
        margin-top: 12px;
        font-size: 15px;
        line-height: 26px;
        color: #ff8522;
      }
    }

    .emphasis-txt {
      margin-top: 12px;
      font-size: 15px;
      line-height: 26px;
      color: #ff8522;
    }

    .report-bottom-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 85px;
      text-align: center;

      .bottom-title {
        font-size: 22px;
        line-height: 22px;
        font-weight: 4100;
        color: #444444;
      }

      .btn_type04 {
        margin-top: 20px;
      }

      .helper-txt {
        margin-top: 35px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}

@media (max-width: 1023px) {
  .esg {
    margin: 20px 0 50px;
    padding-bottom: 0;

    .cp_box {
      padding: 0 20px;
    }
    .esg_box {
      padding: 30px 15px 10px;

      th,
      td {
        padding: 5px;
        font-size: 13px;
        line-height: 1.3;
      }
      sup {
        line-height: inherit;
      }
    }

    .ui-btn-wrap {
      margin-top: 40px;
      .ui-btn {
        padding: 0 22px;
        font-size: 16px;
        line-height: 40px;
      }
    }

    .esg_report {
      padding: 50px 20px;
      margin-top: 60px;

      .report-title-wrap {
        .report-title {
          margin-top: 20px;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .report-bottom-wrap {
        margin-top: 40px;

        .helper-txt {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
