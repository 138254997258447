<template>
  <div id="ESG">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg_esg02">
          <h3 class="title title_esg_sm">환경경영</h3>
          <p class="txt txt_esg_sm">
            SK텔링크는 환경경영 체계를 구축하여<br />
            다양한 환경경영 활동을 추진하고 있습니다.
          </p>
          <img
            src="/content/images/sub_header_bg_esg02_mo.jpg"
            alt="비쥬얼 이미지"
          />
        </div>
        <div class="company esg">
          <div class="cp_area pb0">
            <div class="cp_box">
              <p class="cp_sub">
                SK텔링크는 ‘탄소절감’이라는 환경경영 목표를 기반으로 미래를 위한
                녹색 전환을 이루기 위해 <br class="pc-only" />
                전사 차원의 노력을 강화하고 있습니다. 지속 가능한 미래
                구현이라는 환경 경영 비전을 달성하기 위해<br class="pc-only" />
                선제적 기후변화 대응, 환경 경영 시스템 고도화, 친환경 Green
                Culture 조성을 적극적으로 실천하고 있습니다.
              </p>
            </div>
          </div>

          <div class="esg_report">
            <div class="esg_content">
              <div class="env_tree_wrap">
                <dl class="tree_desc_list">
                  <dt>비전</dt>
                  <dd>지속가능한 미래 구현</dd>
                </dl>
                <div class="tree_divider_01" />
                <dl class="tree_desc_list">
                  <dt>목표</dt>
                  <dd>탄소절감</dd>
                </dl>
                <div class="tree_child_wrap">
                  <div class="tree_divider_02" />
                  <div class="tree_child_inner">
                    <div class="tree_child">
                      <div class="tree_child_title first">
                        <div>선제적 기후변화 대응</div>
                      </div>
                      <ul class="tree_child_list">
                        <li>Eco-friendly 인프라 전환</li>
                        <li>Global Initiative 적용 및 이행</li>
                      </ul>
                    </div>
                    <div class="tree_child">
                      <div class="tree_child_title">
                        <div>환경 경영 시스템 고도화</div>
                      </div>
                      <ul class="tree_child_list">
                        <li>IT 기반 환경 관리 시스템 구축</li>
                        <li>친환경 구매 시스템 확보</li>
                        <li>전문인력 구성 및 교육</li>
                      </ul>
                    </div>
                    <div class="tree_child">
                      <div class="tree_child_title">
                        <div>
                          친환경 <br class="pc-only" />
                          Green Culture 조성
                        </div>
                      </div>
                      <ul class="tree_child_list">
                        <li>ICT Biz. Eco-Impact 강화</li>
                        <li>친환경 사회공헌 활동 추진</li>
                        <li>구성원 환경교육 강화</li>
                        <li>투명한 환경정보 공개</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="esg_box">
            <div class="esg_content">
              <h5 class="esg_content_title">환경경영 정책</h5>
              <p class="esg_content_sub">
                SK텔링크의 환경경영 정책은 고객, 비즈니스 파트너, 주주 및 사회의
                다양한 이해관계자들의 행복을 위해 지속가능한 세상을 만드는데
                목적이 있습니다.<br class="pc-only" />
                SK텔링크는 환경 친화적 기업 활동을 영위함으로써 환경 보전 및
                개선을 위해 최선을 다하고 있습니다.<br class="pc-only" />
                SK텔링크의 기업 활동을 영위하는 모든 구성원, 협력업체 등에
                SK텔링크 환경경영 정책을 적용합니다.<br class="pc-only" />
                아울러, 당사의 고객이나 비즈니스 파트너 등에도 환경정책의 준수를
                기대합니다.
              </p>
              <div class="esg_order_list_wrap">
                <dl class="esg_order_list">
                  <dt>
                    01. 환경 경영 시스템 (Environmental Management System)
                  </dt>
                  <dd>
                    SK텔링크는 환경 관련 법규 준수를 기본으로 하며 ISO14001과
                    함께 환경경영 시스템을 구축하고 내부 기준을 수립하고
                    준수합니다.
                  </dd>
                  <dd>
                    SK텔링크는 생산 활동 및 사업 시설의 환경에 미치는 영향을
                    최소화하기 위하여 환경 경영의 목표 수립부터 계획, 실행,
                    개선에 이르기까지 전 과정을<br class="pc-only" />
                    체계적으로 수립하고 정기적으로 평가합니다.
                  </dd>
                </dl>
                <dl class="esg_order_list">
                  <dt>
                    02. 생산 활동 및 사업 시설 (Production Activities and
                    Business Facilities)
                  </dt>
                  <dd>
                    SK텔링크는 생산 활동 및 사업 시설 전반에 걸쳐 환경경영을
                    핵심과제로 정의하고 있으며, 온실가스 감축 및 오염물질
                    최소화를 통해 지구 환경을 보전하고 있습니다.
                  </dd>
                  <dd>
                    SK텔링크는 친환경 ICT 상품 및 서비스 제공을 통해 환경을
                    보호해야 할 사회적 책임을 성실히 수행하며, 기후변화에 따른
                    부정적인 환경 영향을 최소화하기 위해 노력합니다.
                  </dd>
                </dl>
                <dl class="esg_order_list">
                  <dt>03. 유통 및 물류 (Distribution and Logistics)</dt>
                  <dd>
                    SK텔링크는 상품 및 서비스를 공급하는 과정에서 발생할 수 있는
                    환경오염을 방지하기 위하여 환경 관련 시설의 운영에서부터
                    해당 업무를 수행하는<br class="pc-only" />
                    협력회사까지 체계적으로 관리하고 있습니다.
                  </dd>
                </dl>
                <dl class="esg_order_list">
                  <dt>04. 친환경 공급망 (Supply Chain Management)</dt>
                  <dd>
                    SK텔링크는 비즈니스 파트너 ESG 행동 규범(Supplier ESG Code
                    of Conduct)에 따라 공급업체, 서비스 제공자, 계약업체, 기타
                    주요 사업 파트너 등 <br class="pc-only" />
                    협력업체에 대한 ESG 책임 준수 요건을 정의하고 친환경 공급망
                    정책을 수립합니다.
                  </dd>
                </dl>
                <dl class="esg_order_list">
                  <dt>
                    05. 실사, 인수 및 합병 (Due-diligence, mergers and
                    acquisitions)
                  </dt>
                  <dd>
                    SK텔링크는 인수 및 합병 추진 시 Due-diligence를 실시하여
                    환경측면의 법규 혹은 환경관리시스템 기준에 충족하지 못하는
                    잠재적 Risk를 파악하고 <br class="pc-only" />
                    기업 가치 산정에 반영하고 있습니다.
                  </dd>
                </dl>
                <dl class="esg_order_list">
                  <dt>06. 환경 정보 공개 (Disclosure)</dt>
                  <dd>
                    SK텔링크는 환경경영 방침 및 성과에 대해 글로벌 기준에 따라
                    고객, 구성원, 이해관계자에 투명하게 공개합니다.
                  </dd>
                </dl>
                <dl class="esg_order_list">
                  <dt>
                    07. 이해관계자에 대한 인지 향상 (Stakeholders’ Awareness)
                  </dt>
                  <dd>
                    SK텔링크는 내부 및 외부 이해관계자들에게, 환경 및 환경경영의
                    중요성을 인지시키고 효율성을 향상 시키기 위해 노력하고
                    있습니다.
                  </dd>
                  <dd>
                    SK텔링크는 당사의 활동이 환경에 어떤 영향을 끼치는지(영향도,
                    Impact)와 우리 회사가 환경으로부터 어떤 영향을
                    받는지(의존성, Dependency)를 <br class="pc-only" />
                    내부이해관계자들에게 교육하고, 외부 이해관계자들에게
                    공개합니다.
                  </dd>
                </dl>
              </div>
            </div>
            <ul class="esg_pdf_list">
              <li>
                <span class="pdf_title">SK telink 환경경영 정책</span>
                <span class="pdf_txt"></span>
                <a
                  class="pdf_btn"
                  href="/content/download/ESG_환경정책.pdf"
                  target="_blank"
                  >전문 보기</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "EsgSafety",
  metaInfo: {
    title: "환경경영 | 환경 | ESG",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.esg {
  margin: 87px 0 100px;

  .cp_box {
    width: 1190px;

    .cp_sub {
      font-size: 19px;
      line-height: 31px;
    }
  }

  .esg_box {
    padding: 0 0 20px;
    text-align: center;

    .table_type04 {
      margin-top: 20px;
      th,
      td {
        vertical-align: middle;
      }
    }
    sub {
      font-size: 0.5em;
      vertical-align: sub;
    }

    .inner {
      width: 100%;
    }
  }

  .esg_pdf_list {
    margin-top: 52px;
  }

  .esg_content {
    margin-top: 117px;
  }

  .cp_area.pb0 {
    padding-bottom: 0;
  }
  .ui-btn-wrap {
    margin-top: 80px;
    .ui-btn {
      display: inline-block;
      padding: 0 44px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }

  .esg_report {
    margin-top: 87px;
    padding: 100px 20px;
    text-align: center;
    background-color: #f9f9f9;

    * {
      letter-spacing: -0.03em;
    }

    .env_tree_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;

      .tree_desc_list {
        dt {
          font-size: 15px;
          line-height: 15px;
          font-weight: 500;
          color: #f5630e;
        }

        dd {
          margin-top: 8px;
          font-size: 21px;
          line-height: 21px;
          font-weight: 500;
          color: #666666;
        }
      }

      .tree_divider_01 {
        margin: 22px 0;
        width: 2px;
        height: 35px;
        background-color: #ffc89f;
      }

      .tree_child_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 883px;

        .tree_divider_02 {
          position: relative;
          margin: 57px 0px 30px;
          width: calc(100% - 215px);
          height: 50px;
          border-top: solid 2px;
          border-left: solid 2px;
          border-right: solid 2px;
          border-color: #ffc89f;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: -35px;
            transform: translateX(-50%);
            width: 2px;
            height: 35px;
            background-color: #ffc89f;
          }
        }

        .tree_child_inner {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          .tree_child {
            flex: 1;
            max-width: 259px;

            & + .tree_child {
              margin-left: 20px;
            }

            .tree_child_title {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-content: center;
              position: relative;
              padding: 9px;
              border-radius: 99px;
              background-color: #666666;
              min-height: 108px;

              &.first:before {
                content: "추진과제";
                display: block;
                position: absolute;
                left: -104px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 15px;
                line-height: 15px;
                font-weight: 500;
                color: #f5630e;
              }

              > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 19px 20px;
                width: 100%;
                height: 100%;
                border-radius: 99px;
                font-size: 19px;
                line-height: 26px;
                font-weight: 600;
                color: #ffffff;
                background-color: #4c4c4c;
              }
            }

            .tree_child_list {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-left: 35px;
              margin-top: 18px;

              li {
                position: relative;
                padding-left: 7px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 500;
                color: #4c4c4c;

                + li {
                  margin-top: 10px;
                }

                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 8px;
                  width: 2px;
                  height: 2px;
                  background-color: #4c4c4c;
                }
              }
            }
          }
        }
      }
    }

    .esg_content {
      margin-top: 0;
      margin: 0 auto;
      max-width: 1120px;
    }

    .report-title-wrap {
      text-align: center;

      img {
        width: 74px;
        height: 74px;
      }

      .report-title {
        margin-top: 30px;
        font-size: 44px;
        line-height: 44px;
        color: #444444;
      }
    }

    .report-txt {
      margin-top: 25px;
      font-size: 15px;
      line-height: 26px;

      > p {
        margin-top: 12px;
        font-size: 15px;
        line-height: 26px;
        color: #ff8522;
      }
    }

    .emphasis-txt {
      margin-top: 12px;
      font-size: 15px;
      line-height: 26px;
      color: #ff8522;
    }

    .report-bottom-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 85px;
      text-align: center;

      .bottom-title {
        font-size: 22px;
        line-height: 22px;
        font-weight: 4100;
        color: #444444;
      }

      .btn_type04 {
        margin-top: 20px;
      }

      .helper-txt {
        margin-top: 35px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: #9c9c9c;
      }
    }

    .img-wrap {
      margin-top: 46px;
      width: 100%;
      text-align: center;

      img {
        width: 100%;
        max-width: 480px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .esg {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 50px;

    .cp_box {
      padding: 0 20px;
      width: auto;

      .cp_sub {
        font-size: 18px;
        line-height: 31px;
      }
    }
    .esg_box {
      padding: 0 15px 10px;

      sup {
        line-height: inherit;
      }
    }

    .ui-btn-wrap {
      margin-top: 40px;
      .ui-btn {
        padding: 0 22px;
        font-size: 16px;
        line-height: 40px;
      }
    }

    .esg_content {
      .mobile_scroll_view {
        overflow: scroll;

        .inner {
          /* width: 1120px; */
          width: 100%;
          min-width: 882px;
        }
      }

      .risk_assessment.top {
        .esg_inner_title {
          padding-left: 30px;
        }
      }

      .custom_table {
        col {
          width: auto !important;
        }
      }
    }

    .esg_report {
      padding: 50px 20px;
      margin-top: 60px;

      .env_tree_wrap {
        .tree_desc_list {
          dt {
            font-size: 13px;
            line-height: 13px;
            font-weight: 500;
            color: #f5630e;
          }

          dd {
            margin-top: 4px;
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            color: #666666;
          }
        }

        .tree_divider_01 {
          margin: 10px 0;
          height: 25px;
        }

        .tree_child_wrap {
          max-width: 100%;

          .tree_divider_02 {
            margin: 46px 0px 20px;
            width: calc(100% - 40px);
            height: 30px;

            &:before {
              top: -25px;
              height: 25px;
            }
          }

          .tree_child_inner {
            position: relative;

            .tree_child {
              flex: 1;

              & + .tree_child {
                margin-left: 10px;
              }

              .tree_child_title {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-content: center;
                position: static;
                padding: 5px;
                border-radius: 99px;
                background-color: #666666;
                min-height: 50px;

                &.first:before {
                  width: 100%;
                  left: 0;
                  top: -30px;
                  transform: none;
                  font-size: 13px;
                  line-height: 13px;
                }

                > div {
                  padding: 5px;
                  font-size: 13px;
                  line-height: 16px;
                  font-weight: 500;
                }
              }

              .tree_child_list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 10px;
                margin-top: 10px;

                li {
                  padding-left: 7px;
                  font-size: 13px;
                  line-height: 16px;
                  text-align: left;

                  + li {
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }

      .esg_content {
        margin-top: 0 !important;
      }

      .report-title-wrap {
        .report-title {
          margin-top: 20px;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .report-bottom-wrap {
        margin-top: 40px;

        .helper-txt {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
