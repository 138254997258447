<template>
  <div id="SKT_IB">
    <div class="event-wrap t_inbound">
      <div class="event">
        <section class="top-wrap">
          <div class="top-area">
            <img src="/content/images/conslt_top.png" />
            <div class="mvst">
              <a href=""><img src="/content/images/bt_tworld.png" /></a>
              <a href=""><img src="/content/images/bt_post.png" /></a>
            </div>
          </div>
        </section>
        <section class="form-wrap">
          <div class="form-ly">
            <div class="form-box">
              <h1>상담사</h1>
              <ul>
                <li>
                  <dl>
                    <dt>소속</dt>
                    <dd>
                      <input
                        v-model="formData.company"
                        title="전화번호"
                        type="text"
                        value="servicetop"
                        readonly
                      />
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>상담사 사번</dt>
                    <dd>
                      <input
                        v-model="formData.caller_id"
                        title="상담사 사번"
                        type="text"
                        value="123456789"
                        readonly
                      />
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div class="form-box">
              <h1>SK국제전화 요금제 신청</h1>
              <ul class="type">
                <li>
                  <dl>
                    <dt>휴대폰 번호</dt>
                    <dd>
                      <div class="phone-box">
                        <select
                          id="i_sHpNo1"
                          ref="phone1"
                          v-model="phone1"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="">선택</option>
                          <option value="010" selected="">010</option>
                          <option value="011">011</option>
                          <option value="016">016</option>
                          <option value="017">017</option>
                          <option value="018">018</option>
                          <option value="019">019</option>
                        </select>
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="phone2"
                          v-model="phone2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        />
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="phone3"
                          v-model="phone3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        />
                      </div>
                    </dd>
                    <p>* 요금제 등록 시, 안내 문자 발송됩니다.</p>
                  </dl>
                </li>
                <li>
                  <a class="btn-apply" @click="checkChrg">확인</a>
                  <p />
                  <a class="btn-apply" @click="registrationFinal">등록</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="contents">
          <!-- 2020-09-10 텍스트 추가 및 수정 : S-->
          <h1>
            <img
              src="/content/images/tit_script.png"
              alt="00700 요금제 Sales Talk"
            />
          </h1>
          <div class="hard-co">
            <h3>
              국제전화 (001/002) 이용고객 I/B 상담 진행 시, 00700 할인요금제
              안내
            </h3>
            <p>
              <strong>[국제전화 이용고객 (001/002)]</strong><br /><br />
              <em>
                1. 쳥구요금 확인 or 고객DB 확인 中 타사 국제전화요금 부과된 것이
                확인되는 경우</em
              ><br />
              고객님, 혹시 국제전화를 종종이라도 이용하시는 편이시면 가입비나
              기본료는 전혀 없이 주요국가는 분당 110원, 다른 국가도 최대 83%까지
              저렴하게 이용하실 수 있는 <em>00700</em> 할인 요금제를
              가입하시는건 어떠세요?<br />

              별도 비용이 없으니 등록해 두시면 국제전화 이용하실 때마다
              할인혜택이 적용되니까 가끔이라도 국제전화를 이용하는 분들은
              유용다고 말씀하시더라고요 ^^
            </p>
            <h3>가입 동의 시</h3>
            <p>
              네^^ SK텔링크 상품이다 보니 등록을 위해서는 고객님의 정보가
              SK텔링크에 제공되는데 동의하시겠습니까? (동의 여부 확인 필수)<br />
              감사합니다^^<br />
              ***-****-**** 는 <em>00700</em> 할인 받도록 요금제를 정확히 등록해
              드렸고요.<br />
              6개월에 한번이라도 <em>00700</em>을 이용하시면 혜택이 지속
              유지되는 점 참고해 주세요~
              <span class="yellow-box">
                [00700 사용방법] : <em>00700</em> + 국가번호 + 상대방 전화번호
              </span>
              <strong>문자발송_등록성공 시 자동발송</strong><br />
              요금제 신청 되었습니다. 미국,중국,캐나다 110원/분 사용 가능합니다.
              상세문의는 '통화'버튼
            </p>
            <h3>가입 거절한 경우</h3>
            <p>
              유용한 정보라 말씀드렸구요~ 추후라도 국제전화 이용하실 때에는
              <em>00700</em>을 이용 부탁 드립니다.
            </p>
          </div>
          <!-- 2020-09-10 텍스트 추가 및 수정 : E-->
          <h1>
            <img src="/content/images/tit_price.png" alt="주요국 요금비교" />
          </h1>
          <img src="/content/images/img_price.png" />
          <!-- 2020-09-07 text추가 -->
          <p class="txt-r">(단위:원/분, VAT포함)</p>
          <div class="hide">
            국가 미국 일본 중국 캐나다 호주 필리핀 베트남 싱가폴 독일 영국 00700
            110 198 110 110 220 385 550 220 220 220 *Max 75% off *Max 77% off
            *Max 92% off *Max 92% off *Max 83% off *Max 74% off *Max 62% off
            *Max 78% off *Max 83% off *Max 83% off 001 448.8 844.8 1,458.60
            448.8 1,293.60 1,458.60 1,458.60 844.8 1,293.60 1,293.60 002 288 678
            996 1,296 1,086 1,128 1,326 978 942 996
          </div>
        </section>
        <section class="bottom">
          <img src="/content/images/img_bottom.png" />
          <div class="hide">
            <h1>sktelink</h1>
            (04212) 서울시 마포구 마포대로 144 마포T타운.ㅣ대표이사 사장 최영찬ㅣ사업자
            번호104-81-43391<br />
            Copyright © SK Telink CO,LTD. All Rights Reserved.
          </div>
        </section>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from "@/api";
import Popup from "@/components/popup/Popup";
export default {
  name: "SktIb",
  components: {
    Popup,
  },
  data() {
    return {
      formData: {},
      phone1: "",
      phone2: "",
      phone3: "",
      alertPopup: {
        visible: false,
        message: "",
        target: "",
      },
    };
  },
  computed: {
    svc_tel_no() {
      return `${this.phone1}${this.phone2}${this.phone3}`;
    },
  },
  created() {
    this.formData.company = this.$route.query.company;
    this.formData.caller_id = this.$route.query.number;
  },
  methods: {
    checkPhoneNumber() {
      if (!this.phone1) {
        this.alertPopup.target = "phone1";
        this.showAlertPopup("휴대폰 번호를 입력해 주세요.");
        return false;
      } else if (!this.phone2) {
        this.alertPopup.target = "phone2";
        this.showAlertPopup("휴대폰 번호를 입력해 주세요.");
        return false;
      } else if (!this.phone3) {
        this.alertPopup.target = "phone3";
        this.showAlertPopup("휴대폰 번호를 입력해 주세요.");
        return false;
      } else {
        return true;
      }
    },
    showAlertPopup(message) {
      this.alertPopup.message = message;
      this.alertPopup.visible = true;
    },
    alertPopupHandler() {
      this.alertPopup.visible = false;
      this.alertPopup.message = "";
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus();
      }
      this.alertPopup.target = "";
    },
    checkChrg() {
      if (this.checkPhoneNumber()) {
        this.formData.svc_tel_no = this.svc_tel_no;
        api.EventPostSktIbCheck(this.formData);
      }
    },
    registrationFinal() {
      if (this.checkPhoneNumber()) {
        this.formData.svc_tel_no = this.svc_tel_no;
        api.EventPostSktIbEvent(this.formData);
      }
    },
  },
};
</script>

<style></style>
