<template>
  <div id="ESG">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg_esg06">
          <h3 class="title title_esg">자율준수</h3>
          <p class="txt txt_esg_sm">
            SK텔링크는 관련 법규 등에서 규정하고 있는<br />
            책임과 의무를 성실히 수행하고 자율준수프로그램을 강화하여<br />
            이용자 보호에 앞장서겠습니다.
          </p>
          <img
            src="/content/images/sub_header_bg_esg06_mo.jpg"
            alt="비쥬얼 이미지"
          />
        </div>
        <div class="company esg">
          <div class="esg_box">
            <div class="esg_content">
              <h5 class="esg_content_title">자율준수</h5>
              <ul class="esg_content_dot_list">
                <li class="list_txt">
                  비즈니스 이해관계가 이전보다 복잡해지면서 기업의 가치와 성장을
                  담보하는 핵심 요소인 컴플라이언스 또한 더욱 커지고 있습니다.
                  <br class="pc-only" />
                  아울러 ESG 경영의 확산으로 컴플라이언스에 대한 사회와
                  규제당국의 기대 수준도 갈수록 높아지고 있습니다.
                </li>
                <li class="list_txt">
                  이러한 경영 환경 변화에 발맞추어 SK텔링크의 컴플라이언스는
                  사회 전반의 높아진 요구 수준에 폭넓게 부응하고 기업의 사회적
                  책임을 다하기 위해 방송통신위원회의<br class="pc-only" />
                  자율준수 프로그램을 기반으로 프로세스, 콘텐츠와 조직 운영체계
                  등을 지속적으로 혁신하고 고도화하고 있습니다.
                </li>
                <li class="list_txt">
                  앞으로도 SK텔링크는 소극적인 법령 준수나 시장 내의 공정 경쟁의
                  개념을 넘어 모든 법령과 규제, 시장의 요구에 선도적으로
                  부응하며 고객과 사회의 신뢰를 담보하기 위해 보다 체계적이고
                  고도화된 컴플라이언스 정책 비전과 실행 체계를 확립하고 철저한
                  준법경영을 실천하여 고객과 사회로부터 신뢰받는 기업으로 성장해
                  나갈 것입니다.
                </li>
              </ul>
            </div>
            <div class="esg_content mt-107">
              <h5 class="esg_content_title">
                방송통신위원회의 자율준수프로그램 도입
              </h5>
              <div class="esg_content_inner compliance">
                <img
                  src="/content/images/img_esg_compliance01.png"
                  alt="방송통신위원화의 자율준수프로그램 도입"
                />
                <ul class="esg_content_dot_list">
                  <li>
                    <span class="list_txt">
                      2016년 업무 보고 시 ‘조사 제재’에서 ‘시장 자율규제’로
                      전환을 천명하여<br class="pc-only" />
                      “자율준수프로그램(Compliance Program, 이하 CP)” 제도를
                      도입함 (‘16년 5월)
                    </span>
                  </li>
                  <li>
                    <span class="list_txt">
                      사업자들의 자발적인 법규 준수를 유도하여 전기통신사업법
                      위반 행위 감소 목적
                    </span>
                  </li>
                  <li>
                    <span class="list_txt">
                      알뜰폰 사업자 이용자보호업무 평가에 “자율준수프로그램 이행
                      실적”을 평가 항목에 반영하여 매년 심사함
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="esg_content mt-107">
              <h5 class="esg_content_title">자율준수프로그램 제도 주요 내용</h5>
              <div class="esg_cp_list_wrap">
                <h6 class="esg_cp_title">CP제도 주요 내용</h6>
                <ul class="esg_cp_list">
                  <li>
                    <div class="cp_title_wrap">
                      <div class="cp_img_wrap">
                        <img
                          class="cp_img"
                          src="/content/images/img_esg_compliance02.png"
                          alt="최고경영자 자율준수 의지 천명"
                        />
                      </div>
                      <p class="cp_title">
                        최고경영자 <br />자율준수 의지 천명
                      </p>
                    </div>
                    <ul class="esg_content_dot_list">
                      <li class="list_txt">
                        최고경영자의 의지를 회사 홈페이지 등에 게시, 임직원 및
                        일반 공중이 볼 수 있도록 조치
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="cp_title_wrap">
                      <div class="cp_img_wrap">
                        <img
                          class="cp_img"
                          src="/content/images/img_esg_compliance03.png"
                          alt="자율준수관리자 임명 및 자문기구 운영"
                        />
                      </div>
                      <p class="cp_title">
                        자율준수관리자 임명 <br />및 자문기구 운영
                      </p>
                    </div>
                    <ul class="esg_content_dot_list">
                      <li class="list_txt">
                        기업 내 CP제도 운영을 전반적으로 책임지는
                        담당자(자율준수관리자) 임명
                      </li>
                      <li class="list_txt">
                        자율준수와 관련하여 발생하는 부서간 마찰을 조정하고
                        자율준수관리자의 권한과 <br class="pc-only" />
                        책임을 뒷받침하기 위한 자문기구로 임직원으로 구성된
                        자율준수협의회 설치
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="cp_title_wrap">
                      <div class="cp_img_wrap">
                        <img
                          class="cp_img"
                          src="/content/images/img_esg_compliance04.png"
                          alt="자율준수 편람 제작"
                        />
                      </div>
                      <p class="cp_title">자율준수 편람 제작</p>
                    </div>
                    <ul class="esg_content_dot_list">
                      <li class="list_txt">
                        임직원들이 전기통신사업법의 자율준수를 위한 세부
                        지침서(자율준수 편람) 제작 및 배포·게시
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="cp_title_wrap">
                      <div class="cp_img_wrap">
                        <img
                          class="cp_img"
                          src="/content/images/img_esg_compliance05.png"
                          alt="자율준수 교육"
                        />
                      </div>
                      <p class="cp_title">자율준수 교육</p>
                    </div>
                    <ul class="esg_content_dot_list">
                      <li class="list_txt">
                        임직원들이 업무상 전기통신사업법 위반 여부를 알 수
                        있도록 교육프로그램 운영
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="cp_title_wrap">
                      <div class="cp_img_wrap">
                        <img
                          class="cp_img"
                          src="/content/images/img_esg_compliance06.png"
                          alt="자료관리체계 구축"
                        />
                      </div>
                      <p class="cp_title">자료관리체계 구축</p>
                    </div>
                    <ul class="esg_content_dot_list">
                      <li class="list_txt">
                        자율준수 활동에 관한 자료를 기록 또는 게시
                      </li>
                      <li class="list_txt">
                        보관 필요 자료는 책임 소재를 명확히 하여 보관
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="cp_title_wrap">
                      <div class="cp_img_wrap">
                        <img
                          class="cp_img"
                          src="/content/images/img_esg_compliance07.png"
                          alt="내부 점검"
                        />
                      </div>
                      <p class="cp_title">내부 점검</p>
                    </div>
                    <ul class="esg_content_dot_list">
                      <li class="list_txt">
                        자율준수관리자의 책임 하에 전기통신사업법 위반 여부
                        점검, CP제도 주기적 점검 등
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="esg_content mt-107">
              <h5 class="esg_content_title">
                최고 경영자의 자율준수 의지 천명
              </h5>
              <p class="esg_content_sub">
                SK텔링크는 구성원과 모든 이해관계자와 함께 하는 지속가능 성장을
                추구하고<br class="pc-only" />
                ESG 경영을 통한 사회적 가치 실현을 위해 ‘자율준수 방침’을 아래와
                같이 정하여 운영합니다.
              </p>
              <div class="esg_content_inner ceo">
                <ul class="esg_content_dot_list">
                  <li class="list_txt">
                    SK텔링크는 규제기관의 규제 행위 등에 의한 수동적인 관계 법령
                    준수보다는 사업자 자율로 공정경쟁 환경을 조성하고
                    <br class="pc-only" />
                    이용자 보호에 기여하는 것이 기업 경쟁력 강화의 필수 요소임을
                    인식하고 지속적으로 자율준수 의지를 실천하겠습니다.
                  </li>
                  <li class="list_txt">
                    SK텔링크는 단순 구호가 아닌 기업 문화로서의 공정 경쟁·이용자
                    보호의 실천이야말로 진정으로 경쟁력을 확보하고
                    <br class="pc-only" />
                    고객으로부터 신뢰를 얻는 원동력이라는 믿음 아래, 사회적
                    책임을 다하는 기업문화를 정착시켜 나가도록 하겠습니다.
                  </li>
                  <li class="list_txt">
                    AI 기술을 적극 활용하여 고객 니즈를 바탕으로 업무프로세스
                    혁신을 이루고 사회적 약자보호 활동을 지속하는 등
                    <br class="pc-only" />
                    한 차원 높은 CS Company가 되고자 합니다.
                  </li>
                  <li class="list_txt">
                    아울러 관련 법규 등에서 규정하고 있는 책임과 의무를 성실히
                    수행하고 자율준수프로그램을 강화하여 이용자 보호에 앞장서는
                    <br class="pc-only" />
                    한편 구성원은 행복하고 고객은 신뢰, 우리가 속한 사회와는
                    선한 영향력을 주고 받으며 모두가 자랑스러워 하는 기업으로
                    성장해 나가고자 합니다.
                  </li>
                </ul>
                <div class="ceo_nm">SK텔링크(주) 대표이사 사장 최영찬</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "ComplianceProgram",
  metaInfo: {
    title: "자율준수 | 지배구조 | ESG",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.esg {
  margin: 87px 0 100px;

  .esg_box {
    padding: 0 0 20px;
  }

  .esg_content {
    &.mt-107 {
      margin-top: 107px !important;
    }

    .esg_content_inner {
      &.compliance {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 46px 30px 53px;
        margin-top: 36px;

        > img {
          display: block;
          width: 73px;
          height: 73px;
        }

        .esg_content_dot_list {
          margin-top: 32px;

          li {
            padding-left: 0;
            text-align: center;

            .list_txt {
              &:before {
                top: 9px;
              }
            }
          }
        }
      }

      &.ceo {
        padding: 67px 100px 80px;

        .esg_content_dot_list {
          margin-top: 0;

          li {
            &.list_txt {
              font-weight: 500;
              color: #141414;
            }

            + li {
              margin-top: 34px;
            }
          }
        }

        .ceo_nm {
          margin-top: 78px;
          font-size: 18px;
          line-height: 1;
          font-weight: 500;
          color: #141414;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .esg {
    margin: 20px 0 50px;
    padding-bottom: 0;

    .esg_box {
      padding: 30px 15px 10px;
    }

    .esg_content {
      margin-top: 0 !important;

      &.mt-107 {
        margin-top: 60px !important;
      }

      .esg_content_inner {
        &.compliance {
          padding: 30px;
          margin-top: 20px;

          .esg_content_dot_list {
            margin-top: 15px;
          }
        }

        &.ceo {
          padding: 30px;

          .esg_content_dot_list {
            li {
              + li {
                margin-top: 15px;
              }
            }
          }

          .ceo_nm {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
</style>
