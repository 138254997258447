<template>
  <div id="ESG">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg_esg03">
          <h3 class="title title_esg_sm">안전보건</h3>
          <p class="txt txt_esg_sm">
            SK텔링크는 구성원 및 비즈니스 파트너의 생명과 <br />
            안전을 위해 안전보건 경영방침을 수립하고,<br />
            안전보건 경영 의지를 지속적으로 공유하고 있습니다.
          </p>
          <img
            src="/content/images/sub_header_bg_esg03_mo.jpg"
            alt="비쥬얼 이미지"
          />
        </div>
        <div class="company esg">
          <div class="cp_area pb0">
            <div class="cp_box">
              <p class="cp_sub">
                SK텔링크는 '중대재해 Zero'를 목표로 구성원과 협력사 종사자의
                생명과 안전을 지키기 위해 글로벌 수준의 <br class="pc-only" />
                안전보건 관리체계를 구축, 운영하고 있습니다. 사업장의
                유해·위험요인을 선제적으로 파악해 산업재해 감소에 기여하고
                있으며,<br class="pc-only" />
                해마다 전 구성원의 안전 교육 이수를 통해 발생 가능한 사고를
                예방하고 안전의식을 제고하고 있습니다.<br class="pc-only" />
                구성원과 가족을 대상으로 매년 건강검진을 지원하고 있습니다.
              </p>
            </div>
          </div>

          <div class="esg_report">
            <div class="esg_content">
              <h5 class="esg_content_title">안전보건 경영 방침</h5>
              <p class="esg_content_sub">
                SK텔링크는 '중대재해 Zero 목표와 5대 실천사항'으로 구성된
                안전보건 경영방침을 노사가 함께 명문화하여 안전보건 경영 의지를
                사내외에 천명하였고 <br class="pc-only" />
                이를 솔선수범 실천하며 당사의 모든 제품과 서비스, 활동에
                이르기까지 고객, 시민, 사내 구성원과 협력사 종사자의 생명과
                안전을 지키는 데 전사적인 노력을 기울이고 있습니다.<br
                  class="pc-only"
                />
                또한 안전보건 관리체계 세부 이행 지침을 사규화하고 '중대재해
                Zero 목표'를 경영진의 KPI로 설정하여 평가보상 체계에
                반영함으로써 안전보건 경영의 실행력을 높이고 있으며,<br
                  class="pc-only"
                />
                구성원, 협력회사 종사자 등 당사와 관련된 모든 이해관계자가 본
                안전보건 경영 방침을 준수할 것을 요구하고 있습니다.
              </p>
              <div class="img-wrap">
                <img
                  src="/content/images/img_esg_safety01.jpg"
                  alt="안전보건 경영 방침"
                />
              </div>
            </div>
          </div>

          <div class="esg_box">
            <div class="esg_content mt-107">
              <h5 class="esg_content_title">안전보건 관리체계</h5>
              <p class="esg_content_sub">
                SK텔링크는 '중대재해 Zero'를 목표로 고객과 시민, 구성원 및
                종사자의 생명과 안전을 지키는 것을 최우선 가치이자 행동의
                기본원칙임을 명확히 인식하고<br class="pc-only" />
                이를 구현하기 위해 국제표준에 입각한 안전보건 관리규정을
                바탕으로, 중대재해처벌법 시행령에서 규정한 15개 제반 사항을
                반영한 안전보건 관리체계를 구축하여 실행하고 있으며,<br
                  class="pc-only"
                />
                향후 경영 환경 및 관련 법률의 변화에 따라 안전보건 관리체계를
                지속적으로 발전시켜 나갈 것 입니다.
              </p>
              <div class="esg_content_inner safety_management">
                <div class="mobile_scroll_view img_wrap">
                  <div class="inner">
                    <img
                      src="/content/images/img_esg_safety02.png"
                      alt="안전보건 관리체계"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="esg_content">
              <h5 class="esg_content_title">안전보건 목표</h5>
              <p class="esg_content_sub">
                SK텔링크는 '중대재해 Zero목표' 지속 달성을 위해 주요 안전보건
                활동계획을 수립하여 실행하고 있습니다.<br class="pc-only" />
                안전보건 관리체계를 고도화하고 SK텔링크 모든 구성원의 사고 및
                질병 예방을 최우선으로 관련 법령 및 내부 지침을 준수하여<br
                  class="pc-only"
                />
                안전한 업무환경 조성 및 구성원 건강관리를 위한 물적 자원 제공을
                아끼지 않을 계획입니다.
              </p>
              <div class="esg_content_inner safety_target">
                <span class="safety_target_title"
                  >모든 재해율 <span>Zero</span></span
                >
              </div>
            </div>
            <div class="esg_content mt-147">
              <h5 class="esg_content_title">산업안전 위험성평가 수행</h5>
              <p class="esg_content_sub">
                SK텔링크는 각 사업장의 유해•위험요인에 따른 부상 또는 질병의
                가능성과 중대성을 추정 및 결정하고,<br class="pc-only" />
                감소대책을 수립하는 등 해마다 사업장 위험성평가를 자체적으로
                실시하고 있습니다.
              </p>
              <div class="esg_content_inner risk_assessment top">
                <h5 class="esg_inner_title">위험성평가 절차</h5>
                <div class="mobile_scroll_view img_wrap">
                  <div class="inner">
                    <img
                      src="/content/images/img_esg_safety09.png"
                      alt="산업안전 위험성평가 수행"
                    />
                  </div>
                </div>
              </div>
              <div class="esg_content_inner risk_assessment bottom">
                <h5 class="esg_inner_title">위험성평가 단계별 내용</h5>
                <div class="esg_inner_risk_list_wrap">
                  <div class="esg_inner_risk_list">
                    <div class="risk_title">
                      <span class="level">1단계</span>
                      <span class="title">사전준비</span>
                    </div>
                    <div class="risk_txt">
                      위험성평가 실시규정 작성, 위험성 수준 등 확정, 평가에
                      필요한 각종 자료 수집
                    </div>
                  </div>
                  <div class="esg_inner_risk_list">
                    <div class="risk_title">
                      <span class="level">2단계</span>
                      <span class="title">유해 ∙ 위험요인 파악</span>
                    </div>
                    <div class="risk_txt">
                      사업장 순회점검 및 근로자들의 상시적 제안 등을 활용하여<br
                        class="pc-only"
                      />
                      사업장 내 유해 ∙ 위험요인 파악
                    </div>
                  </div>
                  <div class="esg_inner_risk_list">
                    <div class="risk_title">
                      <span class="level">3단계</span>
                      <span class="title">위험성 결정</span>
                    </div>
                    <div class="risk_txt">
                      사업장에서 설정한 허용가능한 위험성의 기준을 비교하여<br
                        class="pc-only"
                      />
                      추정된 위험성의 크기가 허용 가능한지 여부를 판단 및 결정
                    </div>
                  </div>
                  <div class="esg_inner_risk_list">
                    <div class="risk_title">
                      <span class="level">4단계</span>
                      <span class="title"
                        >위험성 감소대책<br />
                        수립 및 실행</span
                      >
                    </div>
                    <div class="risk_txt">
                      위험성 결정 결과 허용 불가능한 위험성 합리적으로 실천
                      가능한 범위에서 가능한한<br class="pc-only" />
                      낮은 수준으로 감소시키기 위한 대책을 수립하고 실행
                    </div>
                  </div>
                  <div class="esg_inner_risk_list">
                    <div class="risk_title">
                      <span class="level">5단계</span>
                      <span class="title">기록 ∙ 공유</span>
                    </div>
                    <div class="risk_txt">
                      위험성평가의 유해 ∙ 위험요인 파악, 위험결정의 내용 및 그에
                      따른 조치사항 등을 기록하고,<br class="pc-only" />
                      작업 전 안전점검회의(TBM) 등을 통해 위험성평가 실시 결과를
                      알리고 해당 작업 종사자에게 공유 ∙ 주지
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="esg_content mt-147">
              <h5 class="esg_content_title">안전보건 관리체계 이행점검</h5>
              <p class="esg_content_sub">
                SK텔링크는 정기적으로 전 사업장을 대상으로 이행점검을 실시하여
                안전보건 관리체계의 적정 이행 여부를 면밀하게 진단하고,<br
                  class="pc-only"
                />
                각 사업장별 안전보건 관리 체계 이행률을 높이기 위해 지속적으로
                노력하고 있습니다.
              </p>
            </div>
            <div class="esg_content mt-127">
              <h5 class="esg_content_title">중대재해 대응 프로세스 확립</h5>
              <p class="esg_content_sub">
                SK텔링크는 중대재해가 발생하거나, 발생할 급박한 위험이 있을
                경우를 대비하여 작업중지, 구호조치, 추가 피해 확산 방지 등을
                위해 상황별, 관계자별 행동지침 매뉴얼을 마련하고,<br
                  class="pc-only"
                />
                즉각적인 대응과 신속한 수습을 통한 인명 안전 확보와 피해 확산
                방지를 위해 위기 대응 프로세스를 일원화하여 재해 단계별로
                예비상황실과 중대재해 위기 대응 상황실을 운영합니다.<br
                  class="pc-only"
                />
                다양한 위기 상황별 시나리오에 따른 중대재해 모의훈련을 실시하여
                구성원과 종사자들의 위기대응 역량 향상을 위해 노력하고 있습니다.
              </p>
              <div class="esg_content_inner serious_disaster">
                <h5 class="serious_disaster_title">중대재해 대응 프로세스</h5>
                <div class="mobile_scroll_view img_wrap">
                  <div class="inner">
                    <img
                      src="/content/images/img_esg_safety10.png"
                      alt="중대재해 대응 프로세스"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="esg_content mt-127">
              <h5 class="esg_content_title">산업재해 조사 프로세스</h5>
              <p class="esg_content_sub">
                SK텔링크는 산업재해나 작업 관련성 질환, 부상 등 산업재해 발생 시
                원인 조사를 실시하여 즉각적인 개선 조치를 실시합니다.<br
                  class="pc-only"
                />
                또한 근본적인 해결을 위한 재발방지대책을 수립하여 실사하고
                위험성평가를 통해 재발방지대책의 적정성을 검토하고 있습니다.<br
                  class="pc-only"
                />
                수립된 재발방지대책에 대해서는 전사에 전파교육을 통해서
                구성원들이 올바로 인식하고 재발 방지를 위해 노력할 수 있도록
                하고 있습니다.
              </p>
              <div class="esg_content_inner industrial_accident">
                <h5 class="industrial_accident_title">
                  산업재해 조사 프로세스
                </h5>
                <div class="mobile_scroll_view img_wrap">
                  <div class="inner">
                    <img
                      src="/content/images/img_esg_safety11.png"
                      alt="산업재해 조사 프로세스"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="esg_content mt-127">
              <h5 class="esg_content_title">안전문화 조성활동</h5>
              <p class="esg_content_sub">
                SK텔링크 전 임직원은 매년 반드시 안전교육을 이수하고 있으며,
                이를 통해 사업장에서 발생 가능한 사고를 예방하고 안전의식을
                제고하고 있습니다.<br class="pc-only" />
                그리고 도급사의 안전 교육 현황을 면밀히 점검하고 비즈니스
                파트너사 요청 시 교육자료와 장소를 제공하여 안전 최우선 경영을
                실현해 나가고 있습니다.
              </p>
              <div class="esg_content_inner">
                <table class="custom_table">
                  <caption>
                    안전문화 조성활동
                  </caption>
                  <colgroup>
                    <col style="width: 17%" />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>교육 내용</th>
                      <th>교육시간</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>정기 안전교육</strong></td>
                      <td>산업안전 및 사고 예방에 관한 사항 등</td>
                      <td>사무직 6시간/반기, 비사무직 12시간/반기</td>
                    </tr>
                    <tr>
                      <td><strong>채용 시 안전 교육</strong></td>
                      <td>산업안전보건법 및 일반 안전 관리에 관한 사항 등</td>
                      <td>채용 시 8시간</td>
                    </tr>
                    <tr>
                      <td><strong>관리감독자 교육</strong></td>
                      <td>작업 공정 유해 • 위험성 및 재해 예방대책 등</td>
                      <td>16시간/년</td>
                    </tr>
                    <tr>
                      <td><strong>직무 교육</strong></td>
                      <td>안전보건관리책임자 직무 교육</td>
                      <td>6시간/2년</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="esg_content">
              <h5 class="esg_content_title">구성원 건강 및 직업성 질환 관리</h5>
              <p class="esg_content_sub">
                SK텔링크는 구성원의 직업성 질환 및 재해의 조기 발견/예방을 위해
                매년 의무적으로 건강검진을 시행하고 있으며<br class="pc-only" />
                구성원의 자발적 건강 증진과 정서관리를 위해 다양한 프로그램을
                지원하고 있습니다.<br class="pc-only" />
                또한 이러한 예방 조치에도 불구하고 직업성 질환 및 재해 발생
                시에는 해당 질환 및 재해에 대한 산업 안전 위험성 평가를
                실시하여<br class="pc-only" />
                발생 원인을 분석하고 근본적으로 제거하여 재발을 방지하고
                있습니다.
              </p>
              <div class="esg_content_inner">
                <table class="custom_table">
                  <caption>
                    안전문화 조성활동
                  </caption>
                  <colgroup>
                    <col style="width: 17%" />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>프로그램</th>
                      <th>프로그램 개요</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>건강검진</strong></td>
                      <td>전 임직원 종합 검진 지원, 구성원 가족 1인 지원</td>
                    </tr>
                    <tr>
                      <td><strong>의료비</strong></td>
                      <td>임직원 및 가족에 대하여 의료비 지원</td>
                    </tr>
                    <tr>
                      <td><strong>건강증진</strong></td>
                      <td>사내 피트니스 시설 보유 및 이용 지원</td>
                    </tr>
                    <tr>
                      <td><strong>정서관리</strong></td>
                      <td>
                        외부 상담 기관을 통한 개인 전문 상담 ‘행복찾기’ 프로그램
                        지원
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "EsgSafety",
  metaInfo: {
    title: "안전보건 | 사회 | ESG",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sub_header {
  padding: 40px 0;

  .title_esg {
    font-size: 50px;
    line-height: 50px;
  }

  .txt_esg {
    font-size: 20px;
    line-height: 31px;
  }
}

.esg {
  margin: 87px 0 100px;

  .cp_box {
    width: 1190px;

    .cp_sub {
      font-size: 19px;
      line-height: 31px;
    }
  }

  .esg_box {
    padding: 0 0 20px;
    text-align: center;

    .table_type04 {
      margin-top: 20px;
      th,
      td {
        vertical-align: middle;
      }
    }
    sub {
      font-size: 0.5em;
      vertical-align: sub;
    }

    .inner {
      width: 100%;
    }
  }

  .esg_content {
    margin-top: 137px;

    &.mt-107 {
      margin-top: 107px;
    }

    &.mt-127 {
      margin-top: 127px;
    }

    &.mt-147 {
      margin-top: 147px;
    }

    .risk_assessment.top {
      .esg_inner_title {
        padding-left: 198px;
      }
    }
  }

  .cp_area.pb0 {
    padding-bottom: 0;
  }
  .ui-btn-wrap {
    margin-top: 80px;
    .ui-btn {
      display: inline-block;
      padding: 0 44px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }

  .esg_report {
    margin-top: 87px;
    padding: 67px 20px;
    text-align: center;
    background-color: #f9f9f9;

    * {
      letter-spacing: -0.03em;
    }

    .esg_content {
      margin-top: 0;
      margin: 0 auto;
      max-width: 1120px;
    }

    .report-title-wrap {
      text-align: center;

      img {
        width: 74px;
        height: 74px;
      }

      .report-title {
        margin-top: 30px;
        font-size: 44px;
        line-height: 44px;
        color: #444444;
      }
    }

    .report-txt {
      margin-top: 25px;
      font-size: 15px;
      line-height: 26px;

      > p {
        margin-top: 12px;
        font-size: 15px;
        line-height: 26px;
        color: #ff8522;
      }
    }

    .emphasis-txt {
      margin-top: 12px;
      font-size: 15px;
      line-height: 26px;
      color: #ff8522;
    }

    .report-bottom-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 85px;
      text-align: center;

      .bottom-title {
        font-size: 22px;
        line-height: 22px;
        font-weight: 4100;
        color: #444444;
      }

      .btn_type04 {
        margin-top: 20px;
      }

      .helper-txt {
        margin-top: 35px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: #9c9c9c;
      }
    }

    .img-wrap {
      margin-top: 46px;
      width: 100%;
      text-align: center;

      img {
        width: 100%;
        max-width: 480px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .sub_header {
    padding: 0;
  }

  .esg {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 50px;

    .cp_box {
      padding: 0 20px;
      width: auto;

      .cp_sub {
        font-size: 18px;
        line-height: 31px;
      }
    }
    .esg_box {
      padding: 0 15px 10px;

      sup {
        line-height: inherit;
      }
    }

    .ui-btn-wrap {
      margin-top: 40px;
      .ui-btn {
        padding: 0 22px;
        font-size: 16px;
        line-height: 40px;
      }
    }

    .esg_content {
      .mobile_scroll_view {
        overflow: scroll;

        .inner {
          /* width: 1120px; */
          width: 100%;
          min-width: 882px;
        }
      }

      .risk_assessment.top {
        .esg_inner_title {
          padding-left: 30px;
        }
      }

      .custom_table {
        col {
          width: auto !important;
        }
      }
    }

    .esg_report {
      padding: 50px 20px;
      margin-top: 60px;

      .esg_content {
        margin-top: 0 !important;
      }

      .report-title-wrap {
        .report-title {
          margin-top: 20px;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .report-bottom-wrap {
        margin-top: 40px;

        .helper-txt {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
